import * as React from "react";

interface Props {
  date: string;
  hours: string;
  place: "Tychy" | "Katowice";
}

export const AgendaMeeting = (props: React.PropsWithChildren<Props>) => {
  const { date, hours, place } = props;
  return (
    <div className="lg:col-span-1 border border-gray-400 rounded-lg p-5 backdrop-blur-sm">
      <h3 className="font-semibold tracking-tight text-gray-900">Data:</h3>
      <p>{date}</p>
      <h3 className="font-semibold tracking-tight text-gray-900 mt-6">Czas trwania:</h3>
      <p>{hours}</p>
      {place && <h3 className="font-semibold tracking-tight text-gray-900 mt-6">Miejsce:</h3>}
      {place === "Tychy" && <p>Tychy, aula ul. Barona 30</p>}
      {place === "Katowice" && (
        <p>
          Katowice
          <br />
          Politechnika Śląska <br />
          Aula Centrum Badawczo-Edukacyjnego
          <br />
          ul. Krasińskiego 8
        </p>
      )}
    </div>
  );
};
